import React from "react"
import query from "query-string"
import { settings } from "boostly-ui2"
import Layout from "../components/Layout"
import Card from "../components/Card"
import Underscore from "../components/Underscore"
import ReviewButton from "../components/ReviewButton"
import Title from "../components/Title"

const IndexPage = props => {
  const params = query.parse(props.location.search)
  return (
    <Layout bgShape={require("../images/shape2.svg")}>
      <Card
        gradient={{ to: settings.colors.green, from: settings.colors.purple }}
      >
        <Title size={4}>
          Glad to hear it{" "}
          <span role="img" aria-label="happy face">
            😊
          </span>
        </Title>
        <Title>Would you be willing to tell others for us?</Title>
        <Underscore />
        <ReviewButton placeId={params.googlePlaceId} />
      </Card>
    </Layout>
  )
}

export default IndexPage
